@import "sass-lib";


@media(min-width: 1px) and (max-width: 1420px){
	.btn-oval{
		height: 40px; line-height: 40px; padding: 0 40px;
		&.btn-pink-large{font-size: 14px;}
	}
	

	header.head-log .wrapper, .box-login .wrapper{width: 1170px;}
	
	header{
		.header-top{font-size: 14px;}
	}
	.box-login{
		h1{font-size: 35px;}
		h2{font-size: 26px;}
		h5{font-size: 18px; line-height: 24px;}
		.text-left{
			min-height: 0; height: 480px;
			h1{margin-bottom: 25px;}
		}
		.title-log{
			padding-left: 50px; margin-bottom: 35px;
			.icwp{
				&.ic_key{background-size: 100%; width: 26px; height: 26px; }
			}
		}
		.box-right-log{
			padding: 50px 18px 30px 18px;    
	        .box-form{
				font-size: 14px;
	        	label{padding-top: 9px; padding-bottom: 9px; padding-left: 25px;}  
	            input[type=text], input[type=password], input[type=email]{font-size: 14px;} 
	            .row_list{
					.col{margin-bottom: 12px;}
				}
				input[type=text], input[type=email], input[type=password], input[type=number], select, textarea{height: 35px;}
	        }
	    	.box-log-bottom{
	    		margin-top: 20px;
	    		a{font-size: 14px;}
	    	}
	    }
	}
	.box-submit-center{
		text-align: right;
	}
	.back_link{line-height: 40px;}
}


@media(min-width: 1px) and (max-width: 1240px){
	h2{font-size: 25px;}
	h3{font-size: 26px; line-height: 30px;}
	h5{font-size: 16px;}
	p{line-height: 18px;}

	.wrapper{width: 820px;}
	.wrap-inner{width: 620px;}
	.btn-oval{
		&.btn-submit{font-size: 14px;}
		&.btn-yellow{font-size: 14px; padding: 0 30px;}
		&.btn-grey-large{font-size: 14px; padding: 0 30px;}
	}
	.hline{padding-bottom: 30px;}

	.row_list{
        .col{
            &.col_mid_full{width: 100%;}
            &.col_mid_8{width: 80%;}
            &.col_mid_7{width: 70%;}
            &.col_mid_66{width: 66.66666666666666%;}
            &.col_mid_65{width: 65%;}
            &.col_mid_6{width: 60%;}
            &.col_mid_55{width: 55%;}
            &.col_mid_5{width: 50%;}
            &.col_mid_45{width: 45%;}
            &.col_mid_4{width: 40%;}
            &.col_mid_35{width: 35%;}
            &.col_mid_33{width: 33.3333333333333%;}
            &.col_mid_3{width: 30%;}
            &.col_mid_25{width: 25%;}
            &.col_mid_2{width: 20%;}
            &.col_mid_16{width: 16.66666666666666%;}
            &.col_mid_1{width: 10%;}
        }
    }

	header{
		.logo{width: 160px;}
	}

	header.head-log .wrapper, .box-login .wrapper{width: 780px;}
	.box-login{
		.text-left{
			h1{margin-bottom: 15px;}
			.btn-oval{margin-top: 40px;}
		}
	}

	.box-form{
		font-size: 14px;
		h2{margin-bottom: 50px;}
		input, select, textarea{font-size: 13px;}
		input[type=text], input[type=email], input[type=password], input[type=number], select, textarea{height: 40px;}
		textarea{height: 70px;}
		bdo{font-size: 16px; line-height: 20px; padding-bottom: 15px;}
	}
	.box-disclaimer{
		margin-top: 20px; margin-bottom: 30px;
		h5{margin-bottom: 10px;}
	}
	.box-status-estimasi{
		margin-bottom: 40px;
		.left-estimasi{width: 35.48%;}
		.right-estimasi{width: 64.50%; padding: 50px 32px 42px 48px;}
	}
	.box-btm-next{
		font-size: 13px; line-height: 20px;
		.left{max-width: 65%}
		.right{max-width: 35%}
	}

	.box-question{
		.list-question{
			padding-left: 60px; padding-bottom: 30px;
			.box-number{width: 35px; height: 35px;}
			.box-quiz{
				font-size: 14px; line-height: 20px;
				label{font-size: 14px;}
				p{line-height: 20px; margin-bottom: 20px;}
				.box-choice-list{
					.radio-custom{
						input[type=radio]{top: 3px;}
					}
				}
			}
		}
	}

	.slidetitle{
		.box-slidetitle{
			height: 40px;
			.list-slidetitle{height: 40px; line-height: 40px; min-width: 85px; font-size: 14px;}
		}
	}

	.dot-noted{font-size: 14px;}
	.box-persetujuan{font-size: 13px; line-height: 20px;}
	.noted-text{font-size: 13px; line-height: 20px;}

	.popup{
		font-size: 14px; line-height: 24px;
		p{line-height: 24px;}
		.inner-popup{width: 780px; padding: 50px 0 35px;}
		.wrap-popup{width: 620px;}
	}
	.box-btn-otp{
		.btn-oval{margin: 0 10px;}
	}
	.back_link{font-size: 14px;}



	.row_list {
		.col {
			> h3 {font-size: 18px;}
			&.ck_block {
				.box-radio-button {
					.radio-custom {margin-bottom: 0;}
				}
			}
		}
	}

	.wrap-inner {
		&.wrap_admin {
			width: 740px;
		}
		&.full {
	        width: auto;
	        .filter {
	            .left {
	                input[type="text"] {width: 270px;}
	                @include placeholder {color: #999;}
	            }
	            input[type="text"] {width: 200px;}
	        }
	        .content_table {
	            table {
	                width: 100%;
	                th,td {
	                    font-size: 14px;
	                    &:last-child {border-right: none; width: 200px;}
	                }
	                th {height: 70px; vertical-align: middle; padding: 10px; color: $blue;}
	                td {
	                    padding: 15px 10px;
	                    a {
	                        color: $blue; text-decoration: underline; display: inline-block; position: relative; margin: 0 5px;
	                        &.round_upadte {
	                        	line-height: 26px;
	                        }
	                    }
	                }
	            }
	        }
	    }
	}
	.data {
		padding: 0 25px 20px;
		.col {
			label {font-size: 14px; width: 140px;}
			.txt {font-size: 14px; line-height: 20px; margin-left: 150px;}
		}
	}
	.data_form {
		padding: 0 25px 20px;
	}
	.ck_check {font-size: 14px; line-height: 18px;}   
	
	/* .table-std, 
	.table-std2, 
	.table-std3 {overflow-x: auto;}  */

	.table-std {
		tr {
			th { 
				font-size: 14px; padding: 8px 5px;   
			}
		}  
		tr {
			td {
				font-size: 12px; padding: 8px 5px;    
				.tb-btn {
					padding: 0 0 0 38px; width: 30px; padding: 0 5px; margin: 0 0 5px 5px;   
					&.update {padding: 0 0 0 28px; background-position: center center; text-indent: -999999px;}
					&.close {
						padding: 0 0 0 28px; background-position: center center; text-indent: -999999px;
						&.c666 {
							padding: 0 0 0 28px; background-position: center center; text-indent: -999999px;
						}
					}
					&.more {line-height: 16px; padding: 7px 0;}  
				} 
			}   
		}
	}   


}